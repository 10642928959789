<template>
  <!-- 项目团队 -->
  <div class="main-contain" v-loading="loading">
    <template v-if="datalist.length > 0">
      <div class="itemcontain" v-for="(item, index) in datalist" :key="index">
        <div class="itemup">
          <span v-if="item.avatar && !item.photo">
            <el-image class="elimage" :src="item.avatar ? item.avatar : avater1"></el-image>
          </span>
          <span v-if="!item.avatar && item.photo">
            <el-image class="elimage" :src="item.photo ? item.photo : avater1"></el-image>
          </span>
          <span v-if="item.avatar && item.photo">
            <el-image class="elimage" :src="item.photo ? item.photo : avater1"></el-image>
          </span>
          <span v-if="!item.avatar && !item.photo">
            <el-image class="elimage" :src="avater1"></el-image>
          </span>
          <div class="itemupright">
            <div class="name">
              <div class="left">{{ item.name }}</div>
              <div class="right" v-if="item.is_leader === 1">负责人</div>
            </div>
            <div class="intro">{{ item.job_name }}</div>
          </div>
        </div>
        <div class="itembottom" v-html="item.intro"></div>
      </div>
    </template>
    <template v-else>
      <div class="is-empty" style="text-align: center;">
        <img :src="no_math" style="width: 100%;" />
        <div class="vr_project">
          <span>暂无项目团队</span>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { getvrteams } from "@/api/vr"
export default {
  name: "Courseintroduction",
  components: {},
  data() {
    return {
      testimage: require("@/assets/test.png"),
      datalist: [],
      no_math: require('@/assets/no_math1.png'),
      avater1: require("@/assets/学生.png"),
      loading: true
    }
  },
  methods: {
    initdata(data) {
      this.getvrteams({
        id: data.id,
        per_page: 100
      })
    },

    //获取虚拟仿真项目团队列表
    getvrteams(params) {
      this.loading = true
      getvrteams(params)
        .then((response) => {
          if (response.code === 0 && response.data) {
            this.datalist = response.data.data
            this.loading = false
          }
        })
        .catch((error) => {
          //console.log(error);
        })
    },
  },
};
</script>
<style lang="scss" scoped>
.main-contain {
  padding: 10px;
  background: #ffffff;

  .itemcontain {
    margin-bottom: 30px;

    .itemup {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .elimage {
        margin-right: 20px;
        width: 60px;
        height: 60px;
        border-radius: 50%;
      }

      .itemupright {
        .name {
          display: flex;
          justify-content: unset;
          align-items: flex-end;

          .left {
            font-size: 18px;
            font-weight: bold;
            color: #000000;
          }

          .right {
            margin-left: 10px;
            width: 52px;
            height: 20px;
            line-height: 20px;
            text-align: center;
            background: rgba(183, 235, 143, 1);
            font-weight: 400;
            font-size: 12px;
            color: #52c41a;
          }
        }

        .intro {
          margin-top: 10px;
          font-size: 14px;
          font-weight: 400;
          color: #666666;
        }
      }
    }

    .itembottom {
      margin-top: 10px;
      margin-left: 80px;
      font-size: 14px;
      line-height: 25px;
      font-weight: 400;
      color: #777;
    }
  }

  @media screen and (max-width: 640px) {
    .vr_project {
      font-size: 16px;
      font-weight: bold;
      color: #b5a2a2;
      margin-bottom: 30px;
    }
  }

  @media screen and (min-width: 640px) and (max-width: 1200px) {
    .vr_project {
      font-size: 16px;
      font-weight: bold;
      color: #b5a2a2;
      margin-bottom: 30px;
    }
  }

  @media screen and (min-width: 1200px) {
    .vr_project {
      font-size: 24px;
      font-weight: bold;
      color: #b5a2a2;
      margin-bottom: 30px;
    }
  }
}
</style>
