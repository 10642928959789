<template>
  <!-- 实验资源 -->
  <div class="main-contain">
    <div class="titlecontian">
      <!-- <el-image class="elimage" :src="icon1" /> -->
      <div class="text1">关联课程:</div>
      <div class="text2" v-for="(item, index) in course_list" :key="index">
        <small @click="toCourseDetail(item)">{{ item.name }}</small>
      </div>
    </div>
    <div class="line"></div>
    <div class="itemcontain" v-for="(item, index) in resource" :key="index">
      <el-image class="elimage" :src="item.cover" />
      <!-- <el-image class="elimage" :src="randomimage()" /> -->
      <div class="rightcontain">
        <div class="introtext">{{ item.name }}</div>
        <div class="introicon">
          <el-image class="elimage" :src="icon2" />
          <div class="text">参与人数:</div>
          <div class="text">{{ item.join_count }}</div>
        </div>
        <div class="enterbt">
          <div class="bt" @click="tourl(item)">进入实验</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getVRModuleList,
  getvrmodulelink,
  getVRRelatedList,
} from "@/api/vr"
import { getToken } from "@/utils/auth"

const BaseReqUrl = require("@/utils/base_url.js")

export default {
  name: "Courseintroduction",
  components: {},
  data() {
    return {
      token: getToken() ? true : false,
      testimage: require("@/assets/test.png"),
      icon1: require("@/assets/coursedetails/矢量智能对象 (1).png"),
      icon2: require("@/assets/new/people.png"),
      course_list: [],
      resource: "",
      data_interface_status: "",
      // username: "",
      // password: "",
      module_id: "",
      // dialogVisible: false,
      image01: require("@/assets/defaultimages/1首页--1实验用房使用排行_23.jpg"),
      image02: require("@/assets/defaultimages/1首页--1实验用房使用排行_25.jpg"),
      image03: require("@/assets/defaultimages/1首页--1实验用房使用排行_36.jpg"),
      image04: require("@/assets/defaultimages/1首页--1实验用房使用排行_60.jpg"),
      image05: require("@/assets/defaultimages/1首页--1实验用房使用排行_57.jpg"),
      image06: require("@/assets/defaultimages/1首页--1实验用房使用排行_41.jpg"),
      image07: require("@/assets/defaultimages/1首页--1实验用房使用排行_44.jpg"),
      image08: require("@/assets/defaultimages/1首页--1实验用房使用排行_03.jpg"),
      dialogVisibleCourse: false,
      courseinfo: "",
    }
  },
  created() {
    this.getresourceinfo({ vr_id: this.vr_id })
  },
  inject: ["vr_id"],
  methods: {
    randomimage() {
      let items = [
        this.image01,
        this.image02,
        this.image03,
        this.image04,
        this.image05,
        this.image06,
        this.image07,
        this.image08,
      ]
      let item = items[Math.floor(Math.random() * items.length)]
      return item
    },
    initRelatedList() {
      getVRRelatedList({ id: this.vr_id }).then((res) => {
        this.course_list = res.data.data
      })
    },
    tourl(item) {
      // this.show_input = true;
      let vr_ilabx_token_id = localStorage.getItem("vr_ilabx_token_id")
      if (this.token == true || vr_ilabx_token_id != 'undefined') {
        let params = { id: item.id, vr_ilabx_token_id: vr_ilabx_token_id === "undefined" ? '' : vr_ilabx_token_id }
        getvrmodulelink(params).then((response) => {
          if (response.data) {
            window.open(response.data.url, "_blank")
          }
        })
      } else {
        this.$confirm("当前未登陆账号，成绩不会记录，是否继续", "提示", {
          confirmButtonText: "继续",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            let params = { id: item.id }
            getvrmodulelink(params).then((response) => {
              if (response.data) {
                window.open(response.data.url, "_blank")
              }
            })
            // this.$router.push({
            //   path: "/login",
            // });
          })
          .catch(() => { })
      }
    },
    initdata(data) {
      this.initRelatedList()
    },
    // 打开课程章节弹框
    toCourseDetail(item) {
      this.$router.push({
        path: "/home/wisdompracticeteaching/courselearning",
        query: {
          course_semester_sn: item.course_semester_sn,
        },
      });
      window.localStorage.setItem("menuId", 2);
      this.$store.dispatch("setmenuid", 2);
      // let resRoute = this.$router.resolve({
      //   path:
      //     "/home/wisdompracticeteaching/courselearning?course_semester_sn=" +
      //     item.course_semester_sn
      // })
      // window.open(resRoute.href, '_blank')
    },
    getresourceinfo(params) {
      getVRModuleList(params)
        .then((response) => {
          if (response.data && response.data.length > 0) {
            this.resource = response.data
          }
        })
        .catch((error) => {
          //console.log(error)
        })
    },
  },
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 640px) {
  .main-contain {
    padding: 10px;
    background: #ffffff;

    .titlecontian {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .elimage {
        margin-right: 30px;
        width: 18px;
        height: 15px;
      }

      .text1 {
        font-size: 16px;

        font-weight: bold;
        color: #000000;
      }

      .text2 {
        margin-left: 10px;
        font-size: 18px;
        font-weight: bold;
        color: #3d84ff;
        margin-top: -3px;
        cursor: pointer;
      }
    }

    .line {
      margin-top: 10px;
      //   margin-bottom: 10px;
      border-bottom: 1px solid #cccccc;
    }

    .itemcontain {
      //    border: 1px solid red;
      margin-top: 20px;
      // display: flex;
      // justify-content: flex-start;
      // align-items: flex-start;

      .elimage {
        // margin-right: 30px;
        width: 100%;
        height: 240px;
        border-radius: 5px;
      }

      .rightcontain {
        //  border: 1px solid red;
        flex: 1;

        .introtext {
          font-size: 16px;
          font-weight: 500;
          color: #000000;
          margin-top: 10px;
        }

        .introicon {
          margin-top: 20px;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .elimage {
            margin-right: 10px;
            width: 18px;
            height: 19px;
          }

          .text {
            margin-right: 10px;
            font-size: 15px;

            font-weight: 400;
            color: #666666;
          }
        }

        .enterbt {
          // border: 1px solid red;
          margin-top: 20px;
          margin-right: 20px;
          display: flex;
          justify-content: flex-end;
          align-items: center;

          .bt {
            width: 100px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            background: #3d84ff;
            border-radius: 4px;
            font-size: 16px;
            font-weight: bold;
            color: #ffffff;
            user-select: none;
            cursor: pointer;
          }
        }
      }
    }

    .username_title {
      margin-bottom: 20px;
    }

    .containCourse {
      height: 600px;
      overflow-y: auto;
      margin-right: -15px;

      .item {
        margin-top: 20px;

        .item-title {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .item-title-text {
            margin-right: 30px;
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #666666;
          }
        }

        .item-item {
          margin-top: 20px;
          margin-left: 70px;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .item-item-icon {
            width: 12px;
            height: 12px;
            background: #c6c6d3;
            border-radius: 50%;
          }

          .item-item-num {
            margin-right: 10px;
            margin-left: 10px;
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #666666;
          }

          .item-item-text {
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #666666;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 640px) and (max-width: 1200px) {
  .main-contain {
    padding: 10px;
    background: #ffffff;

    .titlecontian {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .elimage {
        margin-right: 30px;
        width: 18px;
        height: 15px;
      }

      .text1 {
        font-size: 16px;

        font-weight: bold;
        color: #000000;
      }

      .text2 {
        margin-left: 10px;
        font-size: 18px;
        font-weight: bold;
        color: #3d84ff;
        margin-top: -3px;
        cursor: pointer;
      }
    }

    .line {
      margin-top: 10px;
      //   margin-bottom: 10px;
      border-bottom: 1px solid #cccccc;
    }

    .itemcontain {
      //    border: 1px solid red;
      margin-top: 20px;
      // display: flex;
      // justify-content: flex-start;
      // align-items: flex-start;

      .elimage {
        // margin-right: 30px;
        width: 100%;
        height: 240px;
        border-radius: 5px;
      }

      .rightcontain {
        //  border: 1px solid red;
        flex: 1;

        .introtext {
          font-size: 16px;
          font-weight: 500;
          color: #000000;
          margin-top: 10px;
        }

        .introicon {
          margin-top: 20px;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .elimage {
            margin-right: 10px;
            width: 18px;
            height: 19px;
          }

          .text {
            margin-right: 10px;
            font-size: 15px;

            font-weight: 400;
            color: #666666;
          }
        }

        .enterbt {
          // border: 1px solid red;
          margin-top: 20px;
          margin-right: 20px;
          display: flex;
          justify-content: flex-end;
          align-items: center;

          .bt {
            width: 100px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            background: #3d84ff;
            border-radius: 4px;
            font-size: 16px;
            font-weight: bold;
            color: #ffffff;
            user-select: none;
            cursor: pointer;
          }
        }
      }
    }

    .username_title {
      margin-bottom: 20px;
    }

    .containCourse {
      height: 600px;
      overflow-y: auto;
      margin-right: -15px;

      .item {
        margin-top: 20px;

        .item-title {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .item-title-text {
            margin-right: 30px;
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #666666;
          }
        }

        .item-item {
          margin-top: 20px;
          margin-left: 70px;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .item-item-icon {
            width: 12px;
            height: 12px;
            background: #c6c6d3;
            border-radius: 50%;
          }

          .item-item-num {
            margin-right: 10px;
            margin-left: 10px;
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #666666;
          }

          .item-item-text {
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #666666;
          }
        }
      }
    }
  }
}


@media screen and (min-width: 1200px) {
  .main-contain {
    padding: 10px;
    background: #ffffff;

    .titlecontian {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .elimage {
        margin-right: 30px;
        width: 18px;
        height: 15px;
      }

      .text1 {
        font-size: 16px;

        font-weight: bold;
        color: #000000;
      }

      .text2 {
        margin-left: 10px;
        font-size: 18px;
        font-weight: bold;
        color: #3d84ff;
        margin-top: -3px;
        cursor: pointer;
      }
    }

    .line {
      margin-top: 10px;
      //   margin-bottom: 10px;
      border-bottom: 1px solid #cccccc;
    }

    .itemcontain {
      //    border: 1px solid red;
      margin-top: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      .elimage {
        margin-right: 30px;
        width: 240px;
        height: 140px;
        border-radius: 5px;
      }

      .rightcontain {
        //  border: 1px solid red;
        flex: 1;

        .introtext {
          font-size: 16px;

          font-weight: 500;
          color: #000000;
        }

        .introicon {
          margin-top: 20px;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .elimage {
            margin-right: 20px;
            width: 18px;
            height: 19px;
          }

          .text {
            margin-right: 10px;
            font-size: 15px;

            font-weight: 400;
            color: #666666;
          }
        }

        .enterbt {
          // border: 1px solid red;
          margin-top: 60px;
          margin-right: 20px;
          display: flex;
          justify-content: flex-end;
          align-items: center;

          .bt {
            width: 100px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            background: #3d84ff;
            border-radius: 4px;
            font-size: 16px;
            font-weight: bold;
            color: #ffffff;
            user-select: none;
            cursor: pointer;
          }
        }
      }
    }

    .username_title {
      margin-bottom: 20px;
    }

    .containCourse {
      height: 600px;
      overflow-y: auto;
      margin-right: -15px;

      .item {
        margin-top: 20px;

        .item-title {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .item-title-text {
            margin-right: 30px;
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #666666;
          }
        }

        .item-item {
          margin-top: 20px;
          margin-left: 70px;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .item-item-icon {
            width: 12px;
            height: 12px;
            background: #c6c6d3;
            border-radius: 50%;
          }

          .item-item-num {
            margin-right: 10px;
            margin-left: 10px;
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #666666;
          }

          .item-item-text {
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #666666;
          }
        }
      }
    }
  }
}
</style>
