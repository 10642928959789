<template>
  <!-- 数据分析 -->
  <div class="main-contain">
    <div class="title">
      实验完成人次
      <span style="font-size: 12px; color: red; position: relative; left: 5px"
        >以下数据根据筛选条件生成</span
      >
    </div>
    <div class="mathTop">
      <template>
        <el-date-picker
          v-model="timevalue"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
          @change="changeTime"
        ></el-date-picker>
      </template>
    </div>
    <div class="chartcontain">
      <div class="contain">
        <chart1
          height="100%"
          width="100%"
          :startTime="startTime"
          :endTime="endTime"
        />
      </div>
    </div>
    <div style="position: relative; top: 30px">
      <div class="title">
        实验访问时长<span
          style="font-size: 12px; color: red; position: relative; left: 5px"
          >以下数据根据筛选条件生成</span
        >
      </div>
      <div class="mathTop">
        <template>
          <el-date-picker
            v-model="timevalue1"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            @change="changeTime1"
          ></el-date-picker>
        </template>
      </div>
    </div>
    <div class="chartcontain">
      <div class="contain">
        <chart2
          height="100%"
          width="100%"
          :startTime1="startTime1"
          :endTime1="endTime1"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Chart1 from "@/components/Charts/experimentstatistics";
import Chart2 from "@/components/Charts/durationstatistics";
import { number } from "echarts";
export default {
  name: "Courseintroduction",
  components: { Chart1, Chart2 },
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近半年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近二年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 730);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 1095);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      timevalue: "",
      timevalue1: "",
      startTime: "",
      endTime: "",
      startTime1: "",
      endTime1: "",
    };
  },
  methods: {
    initdata(item) {
      //console.log(item)
    },
    changeTime() {
      if (this.timevalue && this.timevalue.length > 0) {
        this.startTime = parseInt(this.timevalue[0] / 1000).toString();
        this.endTime = parseInt(this.timevalue[1] / 1000).toString();
      }
    },
    changeTime1() {
      if (this.timevalue1 && this.timevalue1.length > 0) {
        this.startTime1 = parseInt(this.timevalue1[0] / 1000).toString();
        this.endTime1 = parseInt(this.timevalue1[1] / 1000).toString();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.main-contain {
  background: #ffffff;
  position: relative;
  padding: 20px;
  margin-bottom: 40px;

  .title {
    font-size: 20px;

    font-weight: bold;
    color: #222222;
  }
  @media screen and (max-width: 640px) {
    .mathTop {
      text-align: right;
      position: relative;
      top: 15px;
    }
  }

  @media screen and (min-width: 640px) and (max-width: 1200px) {
    .mathTop {
      text-align: right;
      position: relative;
      top: 15px;
    }
  }


  @media screen and (min-width: 1200px) {
    .mathTop {
      text-align: right;
      position: relative;
      top: -30px;
    }
  }
  .titlemargintop {
    margin-top: 40px;
  }

  .titlemargintop2 {
    margin-top: 40px;
  }

  .chartcontain {
    margin-top: 20px;
    margin-bottom: 20px;
    position: relative;
    height: 400px;

    .contain {
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;

      .y_time {
        position: absolute;
        transform: rotate(-90deg);
        top: 160px;
        left: 0px;
      }

      .y_time2 {
        left: -20px;
      }

      .x_time {
        margin-top: 10px;
        text-align: center;
      }
    }
  }
}</style>
