<template>
  <!-- 项目介绍 -->
  <div class="main-contain">
    <div class="text">
      <template v-if="brief">
        <div v-html="brief"></div>
      </template>
      <template v-else>
        <div class="is-empty" style="text-align: center;">
          <img :src="no_math">
          <div style="font-size: 24px; font-weight: bold; color: #b5a2a2;margin-bottom: 30px;">
            <span>暂无项目介绍</span>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  name: "Courseintroduction",
  components: {},
  data() {
    return {
      brief: "",
      no_math: require('@/assets/no_math1.png'),
    };
  },
  methods: {
    initdata(data) {
      this.brief = data.brief;
    },
  },
};
</script>
<style lang="scss" scoped>
.main-contain {
  padding: 10px;
  background: #ffffff;
  .text {
    width: 95%;
    text-indent: 2em;
    line-height: 25px;
    margin: 0 auto;
    margin-top: 10px;
    font-size: 15px;
    font-weight: 400;
    color: #666666;
  }
}
</style>
